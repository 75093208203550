import { useState,useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-bootstrap/Carousel';
import Iframe from 'react-iframe';
import {isMobile } from 'react-device-detect';
import ModalWaiting from "../Components/ModalWaiting";
import GetImage from "../Components/GetImage";
import "./App.css";

function FontShow3() {
  const [fontName, setFontName] = useState("");
  const [fontType, setFontType] = useState("");
  const [url, setURL] = useState("");
  const [show, setShow] = useState(false);
  const [help, setHelp] = useState(false);
  const [menuhelp, setMenuHelp] = useState(false);
  const [index, setIndex] = useState(-1);
  const [font,setFont] = useState("");
  const [modalshow, setModalshow] = useState(true);
  const [error, setError] = useState(false);
  const [code, setCode] = useState(GetImage("base"));

//https://learning.smarteasypos.net/font?font=kafupenjitai
//https://drive.google.com/file/d/1_ozmBAYG18m6O2rPFV6Hqg7dGLemqY67/view?usp=drive_link
//https://drive.google.com/file/d/1bUm6O_vvWKE1nVx2nQzqhRxN0UKUWym2/view?usp=drive_link
//https://drive.google.com/file/d/1bcXUxuv-uskClQBe_ltNpVsWuFh8KKRh/view?usp=drive_link
//https://drive.google.com/file/d/1c4CIwtjDOptHA1ZAlRczhyPKgXaQqNtp/view?usp=drive_link
//https://drive.google.com/file/d/1cJGOUEkoFCUD-8QYNJ5eA3ytMhQq0-_S/view?usp=drive_link
//https://drive.google.com/file/d/1cQOQr3KQeG6mkU2D19uAeOTTyY1CLqD_/view?usp=drive_link
//https://drive.google.com/file/d/1cR1IH_2q8dHgIdozpPwLDyokknuv4mTo/view?usp=drive_link
//https://drive.google.com/file/d/1cia4QjmeHuTresw_2Z1KhZVuiMpMKYvv/view?usp=drive_link
//https://drive.google.com/file/d/1cqlbfutwRtq3vTvuKJIuABQxEFg0YwZ9/view?usp=drive_link
/***
  const fontinfotable = [{font:"notosansjp",fontname:"Noto Sans JP",browser:"1OqMZsVVHJDkShoXiQXHNdq47LDBQIuYL",mobile:"1P7Y2bLlCfXB3KMbsIvbmKHve9SohAshZ"},
						 {font:"MPlus",fontname:"M+",browser:"1OqMZsVVHJDkShoXiQXHNdq47LDBQIuYL",mobile:"1P7Y2bLlCfXB3KMbsIvbmKHve9SohAshZ"},
						 {font:"RoundedMPlus",fontname:"Rounded M+",browser:"DAGaVcMys0g/KTg32YzfbjhEoi0uBSetQw",mobile:"1P7Y2bLlCfXB3KMbsIvbmKHve9SohAshZ"},
						 {font:"GenjuGothic",fontname:"源柔ゴシック",browser:"DAGaVcMys0g/KTg32YzfbjhEoi0uBSetQw",mobile:"1P7Y2bLlCfXB3KMbsIvbmKHve9SohAshZ"},
						 {font:"ChikushiAMaruGothic",fontname:"筑紫A丸ゴシック",browser:"DAGaVcMys0g/KTg32YzfbjhEoi0uBSetQw",mobile:"1P7Y2bLlCfXB3KMbsIvbmKHve9SohAshZ"},
						 {font:"MotoyaMaAporo",fontname:"モトヤアポロ",browser:"DAGaVcMys0g/KTg32YzfbjhEoi0uBSetQw",mobile:"1P7Y2bLlCfXB3KMbsIvbmKHve9SohAshZ"},
						 {font:"TeropMincho",fontname:"テロップ明朝",browser:"DAGaVcMys0g/KTg32YzfbjhEoi0uBSetQw",mobile:"1P7Y2bLlCfXB3KMbsIvbmKHve9SohAshZ"},
						 {font:"MochiyPopOne",fontname:"Mochiy Pop One",browser:"DAGaVcMys0g/KTg32YzfbjhEoi0uBSetQw",mobile:"1P7Y2bLlCfXB3KMbsIvbmKHve9SohAshZ"}
  						];
***/
//https://learning.smarteasypos.net/font?font=yanchapop
//https://drive.google.com/file/d/1dydiYQkIdV523vaME6yUzKeyzLjtJ711/view?usp=drive_link
//https://drive.google.com/file/d/1e1XXfS4Bl6TfM7vgcjeWZaZrIAMIZLXt/view?usp=drive_link
//https://drive.google.com/file/d/1H4SqSmtV9SzEWELZSkW1y64_P_RyBHXp/view?usp=drive_link
//https://drive.google.com/file/d/1H4tPItHAFVSfolu2w8sQuNrU0xWADqeh/view?usp=drive_link
//https://drive.google.com/file/d/1fbsVF6sPliJhM6UsU-IuG2sqgS3DcjXp/view?usp=drive_link
//https://drive.google.com/file/d/1fcVAXS6jBCqZ7KkenHXZ7QCtjivcC9oG/view?usp=drive_link
//https://drive.google.com/file/d/1fvXJAQw4dyqGMcwdIsKYrbO4RoPYG86e/view?usp=drive_link
//https://drive.google.com/file/d/1gfLFLhv72m2cDccCstVr6zZhhsdL5LyB/view?usp=drive_link
//https://drive.google.com/file/d/1J0DN3kQ2BLB6XLSY74lpRHhtXCCgqQAz/view?usp=drive_link
//https://drive.google.com/file/d/1J87btUoLr7cJ7rjI6BpVIqVumSVqgVLC/view?usp=drive_link
//https://drive.google.com/file/d/1I3S9qVI1Q0vyC-Ccjfx12ROGdHZ3xHun/view?usp=drive_link
//https://drive.google.com/file/d/1I4rmkyOM3ifsNwC5CGrnuKOcJf-db7pU/view?usp=drive_link
//https://drive.google.com/file/d/1imF12TWrf5SlKM_nTuWBAgGnuJywLRXj/view?usp=drive_link
//https://drive.google.com/file/d/1iwCm3k-8gYQBarEaap0S-0P5y7Y8JLtu/view?usp=drive_link
//https://drive.google.com/file/d/1i109L46XKofKwxKPCxHEeKe8dzL4aYf3/view?usp=drive_link
//https://drive.google.com/file/d/1i6QLxEZ8rTRwBokjsnKxQQZIA1x_PqnB/view?usp=drive_link
//https://drive.google.com/file/d/1Ivpy7QYE79N6UVi4Z5wWvmmRjnn1uWOe/view?usp=drive_link
//https://drive.google.com/file/d/1Iw6eey6J-2eQq_G0Rp3BSkKZTNtvMnAC/view?usp=drive_link

  const fontinfotable = [
  						 {font:"teropmincho",fontname:"テロップ明朝",browser:"1G3SSaJq1QSM0D6vRm5moMs6nMzQj8vsP",mobile:"1_Z3wTr4chX9qHmkwYUPF8dxfP_qP_Ho8"},
  						 {font:"udmincho",fontname:"UD明朝",browser:"1Gef-K2EvMJ2sYkjPPRdGYK-E0KLashdd",mobile:"1Gg-859nvnxKRpb3KT6aGXImkMJGsTtps"},
  						 {font:"chikushimincho",fontname:"筑紫明朝",browser:"1GoqXfdY2_SFDOuaZWy6UOlE360RTjkcO",mobile:"1GuVBEVb2K9K2TLZdxO3ETHyNQlKW-GxK"},
  						 {font:"harenosoramincho",fontname:"はれのそら明朝",browser:"1_iL4x6umWgFuelo9XTkhplyT5lq0r4yk",mobile:"1_ozmBAYG18m6O2rPFV6Hqg7dGLemqY67"},
					     {font:"motoyaaporo",fontname:"モトヤアポロ",browser:"1J0DN3kQ2BLB6XLSY74lpRHhtXCCgqQAz",mobile:"1J87btUoLr7cJ7rjI6BpVIqVumSVqgVLC"},
					     {font:"hannarimincho",fontname:"はんなり明朝",browser:"1I3S9qVI1Q0vyC-Ccjfx12ROGdHZ3xHun",mobile:"1I4rmkyOM3ifsNwC5CGrnuKOcJf-db7pU"},
					     {font:"modemincho",fontname:"モード明朝",browser:"1i109L46XKofKwxKPCxHEeKe8dzL4aYf3",mobile:"1i6QLxEZ8rTRwBokjsnKxQQZIA1x_PqnB"},
					     {font:"kaiseidecol",fontname:"Kaisei Decol",browser:"1imF12TWrf5SlKM_nTuWBAgGnuJywLRXj",mobile:"1iwCm3k-8gYQBarEaap0S-0P5y7Y8JLtu"},
					     {font:"makaroni",fontname:"マカロニ",browser:"1Ivpy7QYE79N6UVi4Z5wWvmmRjnn1uWOe",mobile:"1Iw6eey6J-2eQq_G0Rp3BSkKZTNtvMnAC"},
  						 {font:"notosansjp",fontname:"Noto Sans JP",browser:"1OqMZsVVHJDkShoXiQXHNdq47LDBQIuYL",mobile:"1P7Y2bLlCfXB3KMbsIvbmKHve9SohAshZ"},
  					     {font:"mplus",fontname:"M+",browser:"1EILKsExx3ofTB-bRxMJZMfq7Ha1gDJn0",mobile:"1EPKDDkFJ0xThzQ2AXldVqagB0_WfOBRg"},
					     {font:"roundedmplus",fontname:"Rounded M+",browser:"1SwLeg4s4sc5aM2T5Tal-1_HgNwGuC09N",mobile:"1T07GmR5nXKSS4XLhuoTif_kRWRfcEY6O"},
					     {font:"genjugothic",fontname:"源柔ゴシック",browser:"1WLaNvJbOn6wJRl-fW_kGts_tbEA_GXg0",mobile:"1WMA_VC4hjSwTLCZod8sEZ6djphhQSBTA"},
					     {font:"chikushiamarugothic",fontname:"筑紫A丸ゴシック",browser:"1WxqxhWUI0EmWfqDtMgeftsXk7eL-x0EL",mobile:"1XJfTAZUQq8GoDP1pNTU6NNKx73TWNV17"},
					     {font:"mochiypopone",fontname:"Mochiy Pop One",browser:"1MJdl9sPwOKEJPd0RFJT3Ayj0lm8tnMvJ",mobile:"1MP1kaQ-fEb1w9XZZfiB7Ai1BslRY7MIq"},
					     {font:"geneigothic",fontname:"源暎ゴシック",browser:"1k4uGKhFoKOTbSzLtXs9YQV4woB-dv22N",mobile:"1k9VZGgK7ei-t3O1qALric0AeSP31YfLp"},
					     {font:"sezannu",fontname:"セザンヌ",browser:"1Mrim_mYnBtaR4aWBwTunL3hruJXYIaDh",mobile:"1N2TCZYeaYRhms03VsuxWzTwGPbRyDIjY"},
					     {font:"tsunagigothic",fontname:"つなぎゴシック",browser:"1kCnzNiz7XhhZkFiZ6EZQXmRw2ZL6HZPY",mobile:"1kDmt2jKYSDv6KoXj5vZJa-FKy5UwEPCN"},
					     {font:"zenmarugothic",fontname:"Zen Maru Gothic",browser:"1OIwcRUZF4C8IrEDm4O2SYDg8JBB-_oeh",mobile:"1OJrm9XNH9hTD4q1WR9v0gwMkavxjjKvx"},
					     {font:"motoyaseikasho",fontname:"モトヤ正楷書",browser:"1bUm6O_vvWKE1nVx2nQzqhRxN0UKUWym2",mobile:"1bcXUxuv-uskClQBe_ltNpVsWuFh8KKRh"},
					     {font:"motoyataikai",fontname:"モトヤ大楷",browser:"1c4CIwtjDOptHA1ZAlRczhyPKgXaQqNtp",mobile:"1cJGOUEkoFCUD-8QYNJ5eA3ytMhQq0-_S"},
					     {font:"motoyagyosho",fontname:"モトヤ行書体",browser:"1cQOQr3KQeG6mkU2D19uAeOTTyY1CLqD_",mobile:"1cR1IH_2q8dHgIdozpPwLDyokknuv4mTo"},
					     {font:"kafupenjitai",fontname:"花風ペン字体",browser:"1cia4QjmeHuTresw_2Z1KhZVuiMpMKYvv",mobile:"1cqlbfutwRtq3vTvuKJIuABQxEFg0YwZ9"},
					     {font:"shinekyapshon",fontname:"しねきゃぷしょん",browser:"1dydiYQkIdV523vaME6yUzKeyzLjtJ711",mobile:"1e1XXfS4Bl6TfM7vgcjeWZaZrIAMIZLXt"},
					     {font:"hanazome",fontname:"はなぞめ",browser:"1H4SqSmtV9SzEWELZSkW1y64_P_RyBHXp",mobile:"1H4tPItHAFVSfolu2w8sQuNrU0xWADqeh"},
					     {font:"chibisukefont",fontname:"ちびすけふぉんと",browser:"1fbsVF6sPliJhM6UsU-IuG2sqgS3DcjXp",mobile:"1fcVAXS6jBCqZ7KkenHXZ7QCtjivcC9oG"},
					     {font:"yanchapop",fontname:"やんちゃポップ",browser:"1fvXJAQw4dyqGMcwdIsKYrbO4RoPYG86e",mobile:"1gfLFLhv72m2cDccCstVr6zZhhsdL5LyB"}
					     //{font:"dummy",fontname:"",browser:"",mobile:""}
					    ];
//https://learning.smarteasypos.net/font?font=zenmarugothic
//https://drive.google.com/file/d/1MJdl9sPwOKEJPd0RFJT3Ayj0lm8tnMvJ/view?usp=drive_link
//https://drive.google.com/file/d/1MP1kaQ-fEb1w9XZZfiB7Ai1BslRY7MIq/view?usp=drive_link
//https://drive.google.com/file/d/1k4uGKhFoKOTbSzLtXs9YQV4woB-dv22N/view?usp=drive_link
//https://drive.google.com/file/d/1k9VZGgK7ei-t3O1qALric0AeSP31YfLp/view?usp=drive_link
//https://drive.google.com/file/d/1Mrim_mYnBtaR4aWBwTunL3hruJXYIaDh/view?usp=drive_link
//https://drive.google.com/file/d/1N2TCZYeaYRhms03VsuxWzTwGPbRyDIjY/view?usp=drive_link
//https://drive.google.com/file/d/1kCnzNiz7XhhZkFiZ6EZQXmRw2ZL6HZPY/view?usp=drive_link
//https://drive.google.com/file/d/1kDmt2jKYSDv6KoXj5vZJa-FKy5UwEPCN/view?usp=drive_link
//https://drive.google.com/file/d/1OIwcRUZF4C8IrEDm4O2SYDg8JBB-_oeh/view?usp=drive_link
//https://drive.google.com/file/d/1OJrm9XNH9hTD4q1WR9v0gwMkavxjjKvx/view?usp=drive_link

	useEffect(()=>{
		const search = decodeURIComponent(window.location.search);
	    const query = new URLSearchParams(search);
	    setFont(query.get('font'));
	},[]);
	useEffect(()=>{
		if(font.length > 0 && !help && !menuhelp){
			setModalshow(true);
			console.log(font);
			let nfontinfotable = fontinfotable.map((fontinfo,index)=> {return {...fontinfo,index:index}});
			let table = nfontinfotable.filter(fontinfo => fontinfo.font === font);
			console.log(table);
			if(index > -1){
			    let ntable = nfontinfotable[index];
				let rpath = isMobile ? ntable.mobile: ntable.browser;
				if(isMobile)
					setURL(`https://drive.google.com/file/d/${rpath}/preview`);
				else
					setURL(`https://drive.google.com/file/d/${rpath}/preview?usp=drivesdk`);
				setFontName(ntable.fontname);
				setCode(GetImage(ntable.font));
			}else if(table.length > 0){
				let rpath = isMobile ? table[0].mobile: table[0].browser;
				if(isMobile)
					setURL(`https://drive.google.com/file/d/${rpath}/preview`);
				else
					setURL(`https://drive.google.com/file/d/${rpath}/preview?usp=drivesdk`);
				setFontName(table[0].fontname);
				setIndex(table[0].index);
				setCode(GetImage(table[0].font));
			}else{
				setModalshow(false);
				setError(true);
			}
		}else if(help){
			setModalshow(true);
			//https://drive.google.com/file/d/19JmFrWFyGjSc1H4pv4Y5SArIxbzygvOf/view?usp=drive_link
			let rpath = "19JmFrWFyGjSc1H4pv4Y5SArIxbzygvOf";
			setURL(`https://drive.google.com/file/d/${rpath}/preview?usp=drivesdk`);
		}else if(menuhelp){
			setModalshow(true);
			let rpath = "1FncG5ixl4e2Syt1AYT9ssDTbaifmcO1y";
			//https://drive.google.com/file/d/1FncG5ixl4e2Syt1AYT9ssDTbaifmcO1y/view?usp=drive_link
			setURL(`https://drive.google.com/file/d/${rpath}/preview?usp=drivesdk`);
		}
	},[font,help,menuhelp,index]);
	/***
	useEffect(()=>{
		if(params){
			setCode(GetImage(fontInfo[0]));
			setCode2(GetImage(fontInfo[1]));
		}
	},[params]);
	***/
	const copyclipboard = () =>{
		navigator.clipboard.writeText(fontName);
		setShow(true)
	}
    const handleClose = () => setShow(false);
    const loaded = () => {
		setModalshow(false);
    }
    const showmethod = () => {
    	setHelp(!help);
    	if(menuhelp)
	    	setMenuHelp(!menuhelp);
    }
    const showmenumethod = () => {
    	setMenuHelp(!menuhelp);
    	if(help)
	    	setHelp(!help);
    }
    const countdown = () =>{
    	setIndex(index - 1);
    	setHelp(false);
    	setMenuHelp(false);
    }
    const countup = () =>{
    	setIndex(index + 1);
    	setHelp(false);
    	setMenuHelp(false);
    }
  return (
  		<>
		<style jsx>{`
			@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&display=swap');
			.cardClass{
				height:80vh !important;
			}
			.noto-sans-jp-font {
			  font-family: "Noto Sans JP", serif;
			  font-optical-sizing: auto;
			  font-weight: 400;
			  font-style: normal;
			}
			.iframe-style{
				position: relative;
				width: 100%;
				height: 0;
				padding-top:
				100.0000%;
				padding-bottom: 0;
				box-shadow: 0 2px 8px 0 rgba(63,69,81,0.16);
				margin-top: 1.6em;
				margin-bottom: 0.9em;
				overflow: hidden;
				border-radius: 8px;
				will-change: transform;
			}
			.iframe2{
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				border: none;
				padding: 0;
				margin: 0;
			}
			.iframe{
				width: 100%;
				height: 100%;
			}
			.grid-area {
			  display: grid;
			  height: 100vh;                /* grid全体の高さを設定 */
			  grid-template-rows: 11% 82% 7%;  /* gridの割合が8:2になるよう2行用意 */
			  grid-template-columns: 100%;  /* 横幅いっぱいに1列用意 */
			}
			.top-area {
			  grid-row: 1;
			  grid-column: 1;
			}
			.middle-area {
			  grid-row: 2;
			  grid-column: 1;
			}
			.bottom-area {
			  grid-row: 3;
			  grid-column: 1;
			}
			.w-30 {
				width:30%;
			}
			.w-10 {
				width:10%;
			}
			h1 {
			  position: relative;
			  padding: 1rem 2rem calc(1rem + 10px);
			  background: #fff100;
			}
			h1:before {
			  position: absolute;
			  top: -7px;
			  left: -7px;
			  width: 100%;
			  height: 100%;
			  content: '';
			  border: 4px solid #000;
			}
			.mobile-container {
			  margin: 0;
			  padding: 0;
			  overflow: hidden;
			  height:100vh;
			}
			.mobile-container.iframe {
			  border:none;
			  width:100%;
			  height:100%;
			}
		`}</style>
	  {!isMobile && <Container>
		<Row className="justify-content-md-center noto-sans-jp-font">
		<Col md={1}></Col>
		<Col xs={12} md={10}>
			{ url.length > 0 && <div className="grid-area">
			 <div className="top-area mt-2">
				<Image src={code} fluid />
			 </div>
			  <div className="middle-area mt-2">
				  <Iframe
			        width="100%"
			        height="100%"
				    url={url}
				    onLoad={loaded}
				  />
			 </div>
			  <div className="bottom-area mt-2">
				  <div className="d-flex justify-content-end">
				      <Button className="w-10 me-2" variant="primary" disabled={!(index > 0)} onClick={countdown} size="lg">
				        前へ
				      </Button>
				      <Button className="w-10 me-2" variant="primary" disabled={!(index < fontinfotable.length - 1)} onClick={countup} size="lg">
				        次へ
				      </Button>
				      <Button className="w-25 me-2" variant="primary" onClick={showmenumethod} size="lg">
				        {menuhelp ? "フォント説明": "メニューの使い方"}
				      </Button>
				      <Button className="w-25 me-2" variant="primary" onClick={showmethod} size="lg">
				        {help ? "フォント説明": "コピーの使い方"}
				      </Button>
				      <Button className="w-25 me-2" variant="primary" onClick={copyclipboard} size="lg">
				        フォント名コピー
				      </Button>
			      </div>
		      </div>
		    </div>}
		</Col>
		<Col md={1}></Col>
		</Row>
      </Container>}
	{isMobile &&  url.length > 0 &&<div className="mobile-container">
	<Iframe url={url}
        width="100%"
        height="100%"
        allowfullscreen="allowfullscreen"
        allow="fullscreen"
        onLoad={loaded}
     /></div>}
	    {error &&
	    	<h1>{font} Not Found</h1>
	    }
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="noto-sans-jp-font">コピー終了</Modal.Title>
        </Modal.Header>
        <Modal.Body className="noto-sans-jp-font">"{fontName}"をコピーしました。</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} className="noto-sans-jp-font">
             OK
          </Button>
        </Modal.Footer>
      </Modal>
	  <ModalWaiting show={modalshow} />
      </>
  );
}

export default FontShow3;

/****
		      <Carousel.Item interval={null}>
					<Image src={code} fluid />
		      </Carousel.Item>
		      <Carousel.Item interval={null}>
					<Image src={code2} fluid />
		      </Carousel.Item>
			<div className="grid-area">
				<div className="top-area">
					<Image src={code} fluid />
			    </div>
			    <div className="bottom-area mt-2">
				    <div className="d-grid gap-2">
				      <Button variant="primary" size="lg">
				        Block level button
				      </Button>
				      <Button variant="secondary" size="lg">
				        Block level button
				      </Button>
				    </div>
				</div>
		    </div>
***/

